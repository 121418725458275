@mixin breakpoint($minWidth: 0, $maxWidth: 0, $minHeight: 0, $maxHeight: 0) {
  $type: type-of($minWidth);

  @if $type == string {
    $class: $minWidth;

    @if $class == xxs {
      @media (min-width: 0px) { @content; }
    }

    @if $class == xs {
      @media (min-width: 576px) { @content; }
    }

    @else if $class == sm {
      @media (min-width: 768px) { @content; }
    }

    @else if $class == md {
      @media (min-width: 992px) { @content; }
    }

    @else if $class == lg {
      @media (min-width: 1200px) { @content; }
    }

    @else {
      @warn "Breakpoint mixin supports: xxs, xs, sm, md, lg, xl";
    }
  }
  @else if $type == number {
    $widthQuery: "all" !default;

    // width
    @if $minWidth != 0 and $maxWidth != 0 {
      $widthQuery: "(min-width: #{$minWidth}) and (max-width: #{$maxWidth})";
    }
    @else if $minWidth != 0 and $maxWidth == 0 {
      $widthQuery: "(min-width: #{$minWidth})";
    }
    @else if $minWidth == 0 and $maxWidth != 0 {
      $widthQuery: "(max-width: #{$maxWidth})";
    }

    // height
    $heightQuery: 0;
    @if $minHeight != 0 and $maxHeight != 0 {
      $heightQuery: "(min-height: #{$minHeight}) and (max-height: #{$maxHeight})";
    }
    @else if $minHeight != 0 and $maxHeight == 0 {
      $heightQuery: "(min-height: #{$minHeight})";
    }
    @else if $minHeight == 0 and $maxHeight != 0 {
      $heightQuery: "(max-height: #{$maxHeight})";
    }

    @if $minHeight != 0{
      @media #{$widthQuery} and #{$heightQuery} {
        @content;
      }
    }
    @else if $maxHeight != 0{
      @media #{$widthQuery} and #{$heightQuery} {
        @content;
      }
    }
    @else if $minHeight != 0 and $maxHeight != 0{
      @media #{$widthQuery} and #{$heightQuery} {
        @content;
      }
    }
    @else {
      @media #{$widthQuery} {
        @content;
      }
    }

  }
}

@mixin dropdown($class) {
  @if $class == navbar-scrolled {
    background-color: $color-lightgreen;
    .dropdown-item {
      color: #fff;
      &:hover, &:active, &:focus {
        color: $color-lightgreen;
      }
    }
  } @else {
    background-color: rgba(255,255,255,.7);
  }
}