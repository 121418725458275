@font-face {
  font-family: 'Roboto Slab';
  src: url(../fonts/Roboto_Slab/RobotoSlab-Regular.ttf);
}

@font-face {
  font-family: 'Roboto Slab';
  src: url(../fonts/Roboto_Slab/RobotoSlab-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'Open Sans';
  src: url(../fonts/Open_Sans/OpenSans-Regular.ttf);
}