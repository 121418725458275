.text-accordion {
  .card {
    margin-bottom: 5px;
    .card-header {
      background-color: $color-lightgreen;
      a {
        color: #fff;
      }
    }
  }
}