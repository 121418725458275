header {
  top:0;
  position:fixed;
  width:100%;
  z-index:100;
  background-color: #fff;

  #logo-demeter {
    position:fixed;
    left: 50%;
    transform: translateX(-50%);
    width:75px;
    z-index:1021;

  }

  .navbar-brand {
    @include media-breakpoint-up(lg) {
      img {
        width:350px;
      }
    }
  }

  .user-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .top-header-container {

    transition: 0.2s;
    justify-content: center;
    height:60px;
    @include media-breakpoint-up(lg) {
      height:110px;
      padding-top:10px;
      padding-bottom:10px;
    }
    &.no-height {
      height:0;
      padding:0;
      .navbar-brand > img {height:0}
    }
  }


  .navbar {
    @include media-breakpoint-up(lg) {
      padding:10px 50px 10px 50px;
    }

    .navbar-brand {
      @include media-breakpoint-up(lg) {
          img {
              height:32px;
              width:auto;
          }
      }
    }

    &.navbar-scrolled, &.navbar-content {
      background-color: #eeeee4;
      background-image: url('../img/bg-texture.png');
      -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      -webkit-transition: background-color 300ms linear;
      -ms-transition: background-color 300ms linear;
      transition: background-color 300ms linear;
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: $color-lightgreen;
            text-shadow: none;
            &:hover, &:active, &:focus {
              color: #fff;
              background-color: $color-lightgreen;
            }
          }
          .dropdown-menu {
            @include dropdown('navbar-scrolled');
          }
        }
      }
    }

    //&.navbar-notscrolled {
    //  background-color: transparent;
    //  -webkit-transition: background-color 300ms linear;
    //  -ms-transition: background-color 300ms linear;
    //  transition: background-color 300ms linear;
    //  .navbar-nav {
    //    .nav-item {
    //      text-shadow: 1px 1px $color-text;
    //      .dropdown-menu {
    //        @include dropdown('navbar-notscrolled');
    //      }
    //    }
    //  }
    //}

    .navbar-toggler {
      color: $color-lightgreen;
      font-size: 1.5rem;
    }

    .navbar-nav {
      .nav-item {
        margin-left:1px;
        margin-right:1px;
        .nav-link {
          font-family: 'Roboto Slab';
          color: #fff;
          font-size: .8rem;
          padding-left:15px;
          padding-right:15px;
          text-shadow: 1px 1px $color-text;
          &:hover, &:active, &:focus {
            background: rgba(255,255,255,.7);
            color: $color-darkgreen;
          }
          &.search {
            width: 50px;
          }
          @include breakpoint(lg) {
            font-size: 1.1rem;
          }
        }
        .dropdown-menu {
          .dropdown-item {
            color: $color-darkgreen;
            font-family: 'Open Sans';
            font-size: 1rem;
          }
          background-color: rgba(255,255,255,.7);
          border-radius:0;
          border:none;
        }
      }
    }
  }
}

.user-nav {
  .dropdown-menu {
    padding:15px;
    width:300px;
    background-color: #fff;
    text-shadow: none;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,.125);
    z-index: 2000;
    .nav-item {
      i {
        width:20px;
      }
    }
  }
  .cart-preview {
    .cart-preview-header {
      border-bottom: solid 1px $color-lightgrey;
      padding:5px;
    }
    .cart-preview-body {
        font-size:.8rem;
        padding:5px;

    }
    .cart-preview-footer {
      border-top: solid 1px $color-lightgrey;
    }
  }
}

#cartModal {
  .modal-body {
    h5 {
      color: $color-lightgreen;
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: $color-lightgreen;
}
