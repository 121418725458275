.btn {
  border-radius: 0;
  margin: 10px 0 10px 0;
  font-size: .8rem;
  @include breakpoint(lg) {
      font-size: 1rem;
  }
  &.btn-primary {
    background-color: $color-lightgreen;
    border-color: $color-lightgreen;
    &:hover, &:active, &:focus {
      background-color: $color-darkgreen;
      border-color: $color-darkgreen;
    }
  }
  &.btn-secondary {
    background-color: $color-blue;
    border-color: $color-blue;
    &:hover, &:active, &:focus {
      background-color: $color-darkblue;
      border-color: $color-darkblue;
    }
  }
}

.form-control {
  margin-top:10px;
  margin-bottom:10px;
}


input[required]::-webkit-input-placeholder:after {
    content: " *";
    color: red;
}

input[required]:-moz-placeholder:after {
  content: " *";
  color: red;
}

.required:after { content:" *"; color: red; }