main {

  &#content {

    @include media-breakpoint-up(lg) {
      margin-top:164px;
    }
    //margin-bottom:100px;
  }
  &#home {
    background: url('../img/bg-texture.png');
    @include media-breakpoint-up(lg) {
      margin-top:164px;
    }
  }

  .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .container-fluid {
    //padding-top:30px;
    //padding-bottom:30px;
    &.lightgreen {
      background-color: $color-lightgreen;
    }
    &.darkgreen {
      background-color: $color-darkgreen;
    }
    &.blue {
      background-color: $color-blue;
    }
    &.yellow {
      background-color: $color-yellow;
    }
    &.white {
      background-color: #fff;
    }
    &.texture {
      background: url('../img/bg-texture.png');
    }
  }

  .equal .row {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
  }

  .card-header:first-child {
    border-radius: 0;
  }
  .card-img-top {
    border-radius:0;
  }

  .list-item {
    margin: 30px 0 0 0;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    background-color:#fff;
    &:hover {
      border: solid 1px $color-lightgreen;
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.125);
    }
    h3 {
      margin-bottom:0px;
    }
  }

  .tab-pane {
    background-color: #fff;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 20px 10px 10px 10px;

  }



  .icon-lg {
    display:block;
    font-size:4rem;
    margin-bottom:30px;
    text-decoration: none!important;
  }

  ul:not(.nav):not(.pagination) {
    list-style-type: none;
    padding-left:1rem;
    li {
      list-style-position: inside;
      text-indent: -1em;
      &:before {
        content: "■";
        margin-right:8px;
        color: $color-lightgreen;
      }
    }
  }
}
