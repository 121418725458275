.pagination {
    .page-item {
        .page-link {
            border-color: $color-lightgreen;
            color: $color-text;
        }
    }
    .page-item.active {
        .page-link {
            background-color: $color-lightgreen;
            border-color: $color-lightgreen;
        }
    }
}
