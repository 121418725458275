#newsletter {
  background-color: $color-lightgreen;
  color:#fff;
  margin-top: 50px;
  padding-top:25px;
  #newsletter-tablet {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}