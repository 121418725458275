@import "~bootstrap";
@import '~font-awesome';
@import '~magnific-popup';
@import "fonts";
@import "mixins";
@import "colors";
@import "spinkit";


html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans";
    font-size: .8rem;
   @include breakpoint(lg) {
       font-size: 1rem;
   }
}

main {
  position:relative;
  //padding-top:50px;
}
.container {
  @include breakpoint(lg) {
    max-width:1440px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto Slab";
}

h1, h2, h3 {
  font-weight: 700;
  color: $color-lightgreen;

}

h3 {
  font-size: 2rem;
  @include media-breakpoint-up(lg) {
    font-size: 2.5rem;
  }
  margin-bottom: 1.5rem;
}

.header-color-lightgreen {
  h1, h2, h3, h4 {
    color: $color-lightgreen;
  }
}

.header-color-darkgreen {
  h1, h2, h3, h4 {
    color: $color-darkgreen;
  }
}

.header-color-blue {
  h1, h2, h3, h4 {
    color: $color-blue;
  }
}

.header-color-yellow {
  h1, h2, h3, h4 {
    color: $color-yellow;
  }
}

.header-color-white {
  h1, h2, h3, h4 {
    color: #ffffff;
  }
}

.header-background {
  h1, h2, h3, h4 {
    background-color: rgba(255,255,255,.8);
    display:inline;
    padding:10px 20px;
    margin-bottom:5px;
  }
}

a {
  color: $color-lightgreen;
  &:hover, &:active, &:focus {
    color: $color-darkgreen;
  }
  &.btn-primary {
    background-color: $color-darkgreen;
    color: #fff;
  }

  &.btn-outline-primary {
    color: $color-lightgreen;
    border-color: $color-lightgreen;
    &:hover {
      background-color: $color-darkgreen;
      color: #ffffff;
      border-color: $color-darkgreen;
    }
  }

  &.no-underline {
    text-decoration:none!important;
  }

}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $color-darkgreen;
  border-color: $color-darkgreen;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: $color-darkgreen;
  border-color: $color-darkgreen;
}

//Scroll 2 Top Button Style
.scrollToTop{
  background: rgba(226, 0, 26, 0.7);
  font-size: 30px;
  color: #fff !important;
  text-decoration: none;
  position:fixed;
  bottom:15px;
  right:15px;
  display:flex;
  justify-content: center;
  width: 35px;
  height: 35px;
}
.scrollToTop:hover{
  text-decoration:none;
}

//.center {
//  display:flex;
//  justify-content:center;
//  text-align:center;
//}
//
//.left {
//  display:flex;
//  justify-content: flex-start;
//  text-align:center;
//}
//
//.right {
//  display:flex;
//  justify-content: flex-end;
//  text-align:center;
//}


.img-responsive {
  max-width:100%;
  height:auto;
}

:target {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}


@import "header";
@import "slider";
@import "content";
@import "form";
@import "teaser";
@import "parallax";
@import "accordion";
@import "newsletter";
@import "footer";
@import "blog";
@import "stepwizard";
@import "history";
@import "cookie";
@import "gallery";
@import "search";
@import "pagination";
