.teaser-link {
  &:hover {
    .teaser-item {
      border-color: $color-darkgreen;
    }
  }

}

.card.teaser-item {
  border-radius:0;
  margin-bottom:30px;
  &:hover {
    border: solid 1px $color-lightgreen;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,.125);
    .card-title {
      color: $color-darkgreen;
    }
    cursor: pointer;
  }
  .card-title {
    text-align:center;
    color: $color-lightgreen;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}