/*
 *  Usage:
 *
      <div class="sk-rotating-plane"></div>
 *
 */
@import "../variables";

.sk-rotating-plane {
  width: $spinkit-size;
  height: $spinkit-size;
  background-color: $spinkit-spinner-color;
  margin: $spinkit-spinner-margin;
  animation: sk-rotatePlane 1.2s infinite ease-in-out;
}

@keyframes sk-rotatePlane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  } 50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  } 100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
