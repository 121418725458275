.autocomplete-suggestions {
    width:auto!important;
    background-color: $color-lightgreen;
    color: #fff;
    padding:15px;
}

.search-list {
    .search-results {
        padding-left:0;
        .search-result {
            text-indent: 0;
            margin-bottom:20px;
            &::before {
                content:"";
            }
            .highlight {
                font-weight:bold;
            }
        }

    }
}