.history{
  .history-item{
    position: relative;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg){
      margin-bottom: 0;
      height: 480px;
    }

    &::before{
      content: "";
      position: absolute;
      left: 65px;
      width: 4px;
      margin-left: -2px;
      background-color: #EBE7E7;
      top: 0;
      bottom: -30px;
      z-index: -1;

      @include media-breakpoint-up(lg){
        left: 50%;
        bottom: 0;
      }
    }

    &::after{
      content: "";
      position: absolute;
      left: 65px;
      width: 4px;
      margin-left: -2px;
      background-color: $color-lightgreen;
      top: 0;
      bottom: 100%;
      z-index: -1;
      transition: bottom 1s;

      @include media-breakpoint-up(lg){
        left: 50%;
      }
    }

    &.line-animation{
      &::after{
        bottom: -30px;
        @include media-breakpoint-up(lg){
          bottom: 0;
        }
      }
    }


    .history-year-wrapper{
      width: 100px;
      position: relative;

      @include media-breakpoint-up(lg){
        width: 210px;
      }

      .history-year{
        margin: 0 auto;
        background-color: $color-lightgreen;
        border-radius: 50%;
        border: 2px solid #fff;
        height: 87px;
        width: 87px;
        color: #ffffff;
        line-height: 80px;
        font-size: 20px;
        text-align: center;

        &::before{
          @include media-breakpoint-up(lg){
            content: "";
            position: absolute;
            left: 50%;
            right: 50%;
            top: 50%;
            margin-top: -2px;
            display: block;
            height: 4px;
            background-color: $color-lightgreen;
            transition: right 1s, left 1s;
            z-index: -1;
          }
        }

        &::after{
          @include media-breakpoint-up(lg){
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -6px;
            display: block;
            height: 12px;
            width: 12px;
            background-color: $color_lightgreen;
            border-radius: 50%;
            transition: left 1s, right 1s;
            z-index: -1;
          }
        }

        &.right{
          @include media-breakpoint-up(lg) {
            &::before {
              left: 50%;
              right: 50%;
            }

            &::after {
              left: auto;
              right: 50%;
            }
          }
        }
      }
    }

    &.fade-line-animation, &:first-child{
      .history-year{
        &::before{
          left: 6px;
          right: 50%;
        }

        &::after{
          left: 0;
        }

        &.right{
          @include media-breakpoint-up(lg){
            &::before{
              left: 50%;
              right: 6px;
            }

            &::after{
              left: auto;
              right: 0;
            }
          }
        }
      }
    }

    .history-slider{
      background-color: #EBE7E7;
      opacity: 0;
      transition: opacity 1s;

      .carousel-item{
        &.indicatorsEnabled{
          padding-top: 23px;
        }
      }

      h3{
        padding: 30px 30px 15px 30px;
        font-size: 22px;
        font-weight: bold;
      }

      .text{
        padding: 0 30px 30px 30px;
      }

      .carousel-indicators{
        margin-left: 0;
        margin-right: 0;
        bottom: auto;
        top: 0;
        background-color: $color-lightgrey;

        li{
          &.active{
            background-color: $color-lightgreen;
          }
        }
      }
    }

    &.fade-line-animation, &:first-child{
      .history-slider{
        opacity: 1;
      }
    }
  }

  .history-item-last{
    .history-year-wrapper {
      width: 100px;
      position: relative;

      @include media-breakpoint-up(lg){
        width: 210px;
      }

      .history-year {
        margin: 0 auto;
        background-color: $color-lightgreen;
        border-radius: 50%;
        text-align: center;
        border: 2px solid #fff;
        height: 87px;
        width: 87px;
        line-height: 87px;
        font-size: 20px;
        color: #ffffff;

        @include media-breakpoint-up(lg){
          font-size: 38px;
          line-height: 138px;
          border: 5px solid #fff;
          height: 145px;
          width: 145px;
        }
      }
    }
  }
}
