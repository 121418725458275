#usercentrics-button{

    #uc-banner-modal {
        .uc-banner-content {
            background-color: $color-lightgreen!important;
        }
    }

    .uc-btn-primary {
        background-color: limegreen;
    }

    .uc-btn-default {
        background-color: $color-lightgreen;
    }




  svg{
    *[fill="#3ca1db"]{
      fill:$color-lightgreen;
    }
  }

  .uc-modal-header{
    background-color: #5E5E5E!important;
  }

  #uc-search-consents-info{
    background-color: #5E5E5E!important;
    border-color: #5E5E5E!important;
  }
}

#uc-consents-info-modal{
  .uc-btn{
    background-color: $color-lightgreen!important;
  }
}

#uc-corner-modal{
  .uc-card-header{
    background-color: #5E5E5E!important;
  }

  .uc-nav-tabs{
    background-color: $color-lightgreen!important;
  }
  .uc-btn{
    background-color: $color-lightgreen!important;
  }
  .uc-dropdown-menu{
    background-color: $color-lightgreen!important;
  }

  input[type=checkbox]:checked+label:before{
    background-color: $color-lightgreen!important;
    border-color: $color-lightgreen!important;
  }

  input[type=checkbox].uc-form-check-input.intermediate+label:before{
    background-color: $color-lightgreen!important;
    border-color: $color-lightgreen!important;
  }
}

#uc-central-banner-modal{
  #uc-btn-accept-banner{
    background-color: $color-lightgreen!important;
    border-color: $color-lightgreen!important;
  }
}

#uc-corner-modal{

  .uc-corner-modal-content{
    border: 1px solid #fff;
    border-radius: 8px!important;
  }

  button{
    width: 30px;
  }
}

#usercentrics-button{
  ul{
    li{
      &:before{
        top: auto;
        content: none;
      }
    }
  }

  .uc-dropdown-menu{
    left: -5px;;
    border: 1px solid #fff;
  }

  .uc-form-check{
    line-height: 13px;
  }

  .uc-nav-link{
    line-height: 16px;
    font-size: 14px;
  }

  .uc-dropdown-toggle{
    height: 40px;

    &:after{
      border: none!important;
    }
  }

  #uc-search-consents-info{
    font-size: 14px!important;
  }

  .uc-header-subtitle-text{
    font-size: 14px;
  }
}

#uc-banner-centered{
  .uc-banner-content{
    padding: 10px 10px !important;
  }
}

#uc-btn-open-language-selector-flags{
  margin-left: 10px;
}

.uc-powered-by-footer, .uc-powered-by, .privacy-proxy-video-powered-by{
  display: none!important;
}

.embed-responsive-16by9{
  privacy-proxy-video, privacy-proxy-google-maps{
    margin-top: -56.25%;
  }
}
