$color-darkgreen: #212529;
$color-lightgreen: #e2001a;
$color-blue: #4C88C0;
$color-darkblue: #0e4d84;
$color-yellow: #ffd500;
$color-text: #212529;
$color-darkgrey : #777777;
$color-lightgrey : lightgray;

.red {
    background-color: $color-lightgreen;
    color:#ffffff;
}

.yellow {
    background-color: $color-yellow;
    color: $color-text;
}

.white {
    background-color: rgba(255,255,255,.5);
}
