@keyframes imageAnimate {
  50%  {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}

#slider {
    position:relative;
    &.border-bottom {
        border-bottom: solid 5px $color-lightgreen!important;
    }
    @media screen and (min-width: 2200px) {
        max-height: 800px;
    }

    .carousel-indicators {
        margin-right: 0;
        margin-left: 0;
        bottom: 0;
        top: auto;
        right: 0;
        flex-direction: row;
        align-items: center;
        left: 0;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            margin-right:auto;
            margin-left:2rem;
            bottom:0;
            top:0;
            right:auto;
            flex-direction: column;
            align-items:center;

            &.horizontal {
                flex-direction: row;
                top:auto;
                bottom:-50px;
                margin-left: 3px;
                margin-right:6px;
                right:0!important;
                left:0!important;

                li {
                    border: solid 1px $color-lightgreen;
                }
            }
        }

        li {
            width:20px;
            height:20px;
            margin-right: 6px;
            margin-left: 6px;
            opacity: 1;
            &.active {
                background-color:$color-lightgreen;
            }
            @include media-breakpoint-up(md) {
                width:25px;
                height:25px;
                margin-right: 3px;
                margin-left: 3px;
            }

        }
        // if there are carousel-indicators then padding on mobile
        + .carousel-inner {
            padding-bottom: 2.5rem;
            @include media-breakpoint-up(md) {
                padding-bottom: 0;
            }
        }
    }

    .img-container {
        height:30vh;
        width:100%;
        overflow:hidden;
        position:relative;
        video {
          position:absolute;
          top:50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 100%;
        }
        img {
          height:100vh;
          width:100%;
          object-fit: cover;
          -webkit-animation: imageAnimate 30s infinite;
          -moz-animation: imageAnimate 30s infinite;
          animation: imageAnimate 30s infinite;
        }
        @include breakpoint(lg) {
          height:50vh;
        }
    }

    .carousel-caption {
        position: relative;
        right: auto;
        left: auto;
        bottom: auto;
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        color: $color-text;
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 15%;
            left: 15%;
        }
        .slider-caption-heading {
            text-align: left;
            margin-bottom: 15px;
            //min-width:50%;
            max-width: 100%;
            @include media-breakpoint-up(lg) {
                max-width: 50%;
            }

            &.white, &.red, &.yellow {
                padding: 20px 30px 10px 30px;
                h1, h2, h3, h4, h5, p {
                    color: $color-text;
                }
            }
        }
    }

}

#carousel-with-thumbs {

    .carousel-inner {
        border: solid 1px $color-lightgrey;
    }

    .carousel-indicators {
        margin: 10px 0 0;
        overflow: auto;
        position: static;
        text-align: left;
        white-space: nowrap;
        width: 100%;
        display:flex;
        justify-content: left;
        li {
            background-color: transparent;
            -webkit-border-radius: 0;
            border: solid 1px $color-lightgrey;

            border-radius: 0;
            display: inline-block;
            height: auto;
            margin: 10px 10px 0px 0px !important;
            width: auto;
            img {
                display: block;
                opacity: 0.5;
                &.active {
                    opacity: 1;
                }
                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }
}

.carousel-multi {

    //.carousel-inner {
    //    .carousel-item {
    //        border: solid 1px $color-lightgrey;
    //    }
    //}

    @media (min-width: 768px) {

        .carousel-control-prev {
            justify-content: left;
            width:5%;
        }

        .carousel-control-next {
            justify-content: flex-end;
            width:5%;
        }

        /* show 3 items */
        .carousel-inner .active,
        .carousel-inner .active + .carousel-item,
        .carousel-inner .active + .carousel-item + .carousel-item {
            display: block;
        }

        .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
        .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item,
        .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
            transition: none;
            margin-right: initial;
        }

        .carousel-inner .carousel-item-next,
        .carousel-inner .carousel-item-prev {
            position: relative;
            transform: translate3d(0, 0, 0);
        }

        .carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item {
            position: absolute;
            top: 0;
            right: -33.3333%;
            z-index: -1;
            display: block;
            visibility: visible;
        }

        /* left or forward direction */
        .active.carousel-item-left + .carousel-item-next.carousel-item-left,
        .carousel-item-next.carousel-item-left + .carousel-item,
        .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
        .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
            position: relative;
            transform: translate3d(-100%, 0, 0);
            visibility: visible;
        }

        /* farthest right hidden item must be abso position for animations */
        .carousel-inner .carousel-item-prev.carousel-item-right {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            display: block;
            visibility: visible;
        }

        /* right or prev direction */
        .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
        .carousel-item-prev.carousel-item-right + .carousel-item,
        .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
        .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
            position: relative;
            transform: translate3d(100%, 0, 0);
            visibility: visible;
            display: block;
            visibility: visible;
        }

    }
}




