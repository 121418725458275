.blog-list-item {
  display:flex;
  margin-bottom:30px;
  padding:10px;
  border: 1px solid rgba(0,0,0,.125);
  &:hover {
    border: solid 1px #84C324;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.125);
    cursor: pointer;
  }

}